import React, {useState} from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const NewsletterForm = ({align}) => {
    const [email, setEmail] = useState(null);
    const [notif, setNotif] = useState(null);

    const _handleSubmit = () => {
        addToMailchimp(email)
            .then(({ msg, result }) => {

                if (result !== 'success') {
                    throw msg;
                }
                else {
                    setNotif("Je t’ai ajouté à mes contacts ! Va vite voir ta boite mail…")
                }
            })
            .catch(error => {
                console.log(error) &
                error === "The email you entered is not valid." ? setNotif("L'adresse e-mail que vous avez saisie n'est pas valide.") : setNotif("Pas valide")
            });
    }
    return (
        <>
            <div className="field has-addons" style={{justifyContent: align === "center" && "center"}}>
                <div className="control has-icons-left">
                    <input className="input" type="email" name="email" placeholder="E-mail principal     " onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="control">
                    <button className="button is-dark" onClick={_handleSubmit}>ENVOYER</button>
                </div>
            </div>
            {notif !== "" &&
                <div className={"notification " + notif === "Merci beaucoup !" ? "is-success" : "is-danger"}>
                    <p>{notif}</p>
                </div>
            }
        </>
    );
};

export default NewsletterForm;