import React from "react"
import NewsletterForm from "./NewsletterForm"

const Footer = ({links}) => {
    return (
        <footer className="footer" style={{paddingBottom: "1rem"}}>
            <div className="container">
                <div className="columns is-centered">
                    <div className="column is-4">
                        <h5>Me retrouver</h5>
                        <hr />
                        {
                            links.map(link =>
                                <p><a href={link.link} target="_blank" rel="noopener noreferrer">{link.name}</a></p>
                            )
                        }
                    </div>
                    <div className="column is-4">
                        <h5 className=" is-4">Recevoir mes mails</h5>
                        <hr className="spacer is-1"></hr>
                        <NewsletterForm />
                    </div>
                </div>
            </div>
            <div className="has-text-centered" style={{paddingTop: "4rem"}}>
                <p>© Arthur DF 2020. Tous droits réservés.</p>
            </div>
        </footer>
    )
}
export default Footer


