import React from 'react';
import NewsletterForm from "../NewsletterForm"
import { Remarkable } from 'remarkable';

const SectionNewsletter = ({title, description, headerColor}) => {
    var md = new Remarkable();

    return (
        <section class="hero is-medium" style={{backgroundColor:headerColor}}>
            <div class="hero-body">
                <div class="container has-text-centered">
                    <h3 className="title">{title}</h3>
                    <div
                        className="blog-post-content"
                        dangerouslySetInnerHTML={{ __html: md.render(description) }}
                    />
                    <NewsletterForm align="center" />
                </div>
            </div>
        </section>
    );
};

export default SectionNewsletter;