import React from 'react';
import CourseBox from './courseBox'
import displayPrice from "../Courses/displayPrice"

const ListCourses = ({ courses }) => {
    return (
        <section class="hero">
            <div class="hero-body">
                <div class="container">
                    {courses.map(course =>
                        <CourseBox
                            key={course.node.id}
                            title={course.node.frontmatter.title}
                            price={displayPrice(course.node.frontmatter.price, course.node.frontmatter.price_Discount, [])}
                            slug={course.node.frontmatter.slug}
                            picture={course.node.frontmatter.image}
                        />
                    )}
                </div>
            </div>
        </section>

    );
};

export default ListCourses;