import React from 'react';
import Image from "../Images/Image"
import { Remarkable } from 'remarkable';

const CourseBox = ({title, price, slug, picture}) => {
    var md = new Remarkable();

    return (
        <a href={slug}>
            <div className="columns is-centered is-vcentered">
                    <div className="column is-4">
                        <Image src={picture} style={{borderRadius: "0px"}} />
                    </div>
                    <div className="column is-4">
                        <div
                            className="is-size-5"
                            dangerouslySetInnerHTML={{ __html: md.render(title) }}
                        />
                        <div className="columns is-centered is-vcentered pt-6 is-mobile">
                            <div className="column mobile-is-one-third">
                                <a className="button is-rounded is-dark button-course-home" href={slug}>Voir la formation</a> 
                            </div>
                            <div className="column mobile-is-3" style={{width:"50px"}}>
                                <h5 className="subtitle pl-1 has-text-weight-bold">{price}</h5>
                            </div>
                        </div>
                    </div>
            </div>
        </a>
    );
};

export default CourseBox;