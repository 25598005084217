import React from "react"
import ListCourses from "../components/Homepage/listCourses"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionAbout from "../components/Homepage/sectionAbout"
import SectionNewsletter from "../components/Homepage/sectionNewsletter"
import Footer from "../components/footer"

const IndexPage = ({ data }) => {
  const courses = data.allMarkdownRemark.edges
  const about = data.markdownRemark.frontmatter.about
  const newsletter = data.markdownRemark.frontmatter.newsletter

  return (
    <Layout>
      <SEO />
      <SectionAbout image={about.image} description={about.description} headerColor={about.headerColor}/>
      <SectionNewsletter title={newsletter.title} description={newsletter.description} headerColor={newsletter.headerColor}/> 
      <ListCourses courses={courses} />
      <Footer links={data.markdownRemark.frontmatter.links} />
    </Layout>
  )
}

export default IndexPage


export const indexQuery = graphql`
  query HomePage {
      markdownRemark(frontmatter: {templateKey: {eq: "info"}}) {
        frontmatter {
          links {
            link
            name
          }
          about {
            image
            description
            headerColor
          }
          newsletter{
            title
            description
            headerColor
          }
        }
      }
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "course-page"}}}) {
      edges {
        node {
          id
          frontmatter {
            title
            templateKey
            slug
            price
            price_Discount
            image
          }
        }
      }
    }
  }
`

