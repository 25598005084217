import React from 'react';
import Image from "../Images/Image"
import { Remarkable } from 'remarkable';

const SectionAbout = ({image, description, headerColor}) => {
    var md = new Remarkable();

    return (
        <section class="hero" style={{backgroundColor:headerColor}}>
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-centered">
                        <div class="column is-4">
                            <Image src={image} className="image-home" />
                            <div
                                className="pt-5 has-text-centered"
                                style={{color: "#FFF"}}
                                dangerouslySetInnerHTML={{ __html: md.render(description) }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionAbout;